mgt-person-card.mgt-light {
    --person-card-contact-link-color: #5b5fc7;
    --person-card-contact-link-hover-color:#4f52b2;
    --person-card-show-more-color: #5b5fc7;
    --person-card-show-more-hover-color: #4f52b2;
    --person-card-base-links-color: #5b5fc7;
    --person-card-base-links-hover-color: #4f52b2;
    --person-card-tab-nav-color: #5b5fc7;
    --person-card-active-org-member-color: #5b5fc7;
    --person-card-nav-back-arrow-hover-color: #4f52b2;
    --person-card-nav-back-arrow-color: #5b5fc7;
}
mgt-person-card.mgt-dark {
    background-color: #2D2C2C;
    --person-card-background-color: #2D2C2C;
    
    --person-card-contact-link-color: #9499f5;
    --person-card-contact-link-hover-color:#c5cbfa;
    --person-card-show-more-color: #9499f5;
    --person-card-show-more-hover-color: #c5cbfa;
    --person-card-base-links-color: #9499f5;
    --person-card-base-links-hover-color: #c5cbfa;
    --person-card-tab-nav-color: #9499f5;
    --person-card-active-org-member-color: #9499f5;
    --person-card-nav-back-arrow-hover-color: #c5cbfa;
    --person-card-nav-back-arrow-color: #9499f5;
}
mgt-person-card.mgt-dark.contrast {
    background-color: black;
    border: 1px solid white;
    --person-card-background-color: black;
    --background-color--hover: #1B1A1A;
    --icon-color: white;

    --person-card-contact-link-color: #ffff01;
    --person-card-contact-link-hover-color:#1aebff;
    --person-card-show-more-color: #ffff01;
    --person-card-show-more-hover-color: #1aebff;
    --person-card-base-links-color: #ffff01;
    --person-card-base-links-hover-color: #1aebff;
    --person-card-tab-nav-color: #ffff01;
    --person-card-active-org-member-color: #ffff01;
    --person-card-nav-back-arrow-hover-color: #1aebff;
    --person-card-nav-back-arrow-color: #ffff01;
}